/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
/* @font-face {
  font-family: 'TradeGothic';
  src: local('TradeGothic'), url(./fonts/trade_gothic/used.ttf) format('truetype');
}
@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), url(./fonts/quicksand/used.ttf) format('truetype');
} */

/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* SalesIq chat widget positioning */
/* IMPORTANT: the screen sizes must match what is being used for MUI theme */
/*tablet*/
@media screen and (max-width: 1165px) {
  .zsiq_custommain {
    bottom: 65px !important;
  }
}
/*mobile*/
@media screen and (max-width: 600px) {
  .zsiq_custommain {
    bottom: 65px !important;
  }
}
