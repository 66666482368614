.embla_horizontal {
  width: 100%;
  /* margin: auto; */
  --slide-height: 304px;
  --slide-spacing: 16px;
  --slide-size: 42%;
}
.embla__viewport_horizontal {
  overflow: hidden;
}
.embla__container_horizontal {
  display: flex;
  touch-action: pan-y pinch-zoom;
}
.embla__slide_horizontal {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  /* padding-left: var(--slide-spacing); */
  padding-right: var(--slide-spacing);
}
