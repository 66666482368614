@keyframes bouncing {
    0% {
        bottom: 0;
    }

    50% {
        bottom: 20px;
    }

    100% {
        bottom: 0;
    }
}

.arrow {
    animation: bouncing 1s infinite ease-in-out;
    bottom: 0;
    display: block;
    /* height: 50px;
    left: 50%;
    margin-left: -25px; */
    position: absolute;
}