
.embla_vertical {
  max-width: 768px;
  margin: auto;
  --slide-height: 433px;
  --slide-spacing: 16px;
  --slide-size: 21%;
}
.embla__viewport_vertical {
  overflow: hidden;
}

.embla__container_vertical {
  display: flex;
  touch-action: pan-x pinch-zoom;
  margin-top: calc(var(--slide-spacing) * -1);
  height: calc(var(--slide-spacing) + var(--slide-height));
  flex-direction: column;
}
.embla__slide_vertical {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-height: 130px;
  padding-top: var(--slide-spacing);
}
